import React from 'react';
import './Tools.css';
const Categori2 = ({ handlePageChange , tokenBalance }) => {
  const handleToolClick = (toolName) => {
    if (tokenBalance >= 250000) {
      handlePageChange(toolName);
    }else{
      handlePageChange('redirect');
    }
  };

  return (

    <div className="tools-grid">
      <div className="tool-box" onClick={() => handleToolClick('tool4')}>
        <h2>Alpha Quest</h2>
        <p>Discover Promising Project</p>
        <p>Saved Value: 100 USD monthly</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool5')}>
        <h2>DexLions</h2>
        <p>Token Alert</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool11')}>
        <h2>YardTools Volume Trade</h2>
        <p>Alerts for parameters on coins</p>
        <p>Saved Value: 175 USD monthly</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool12')}>
        <h2>GodBot</h2>
        <p>Find the best tokens</p>
        <p>Saved Value: 150 USD</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool13')}>
        <h2>$PING Bot</h2>
        <p>Find and profit from gem plays</p>
        <p>Saved Value: 1107 USD </p>
      </div>
        <div className="tool-box" onClick={() => handleToolClick('tool10')}>
        <h2>Alpha Hunter</h2>
        <p>Trends, rugs and opentradings</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool8')}>
        <h2>Alpha Hunter</h2>
        <p>Pending dumps on Uniswap</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool9')}>
        <h2>Alpha Hunter</h2>
        <p>Unique tokens tracker</p>
      </div>
      <div className="tool-box">
        <h2>Coming Soon</h2>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

export default Categori2;

