import React, { useState, useEffect } from 'react';
import './App.css'; 
import Tool1Component from './pages/Tool1.js';
import Tool2 from './pages/Tool2.js';
import Tool3 from './pages/Tool3.js';
import Tool4 from './pages/Tool4.js';
import Tool5 from './pages/Tool5.js';
import Tool6 from './pages/Tool6.js';
import Tool7 from './pages/Tool7.js';
import Tool8 from './pages/Tool8.js';
import Tool9 from './pages/Tool9.js';
import Tool10 from './pages/Tool10.js';
import Tool11 from './pages/Tool11.js';
import Tool12 from './pages/Tool12.js';
import Tool13 from './pages/Tool13.js';
import Categori1 from './pages/Categori1.js';
import Categori2 from './pages/Categori2.js';
import Categori3 from './pages/Categori3.js';
import Home from './pages/Home.js';
import RedirectPage from './pages/RedirectPage.js';
import Web3 from 'web3';
import logo from './logo.png';
function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [web3, setWeb3] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState('');
  const handlePageChange = (page) => {
    setCurrentPage(page);
   
  };
 
  useEffect(() => {
    checkWalletConnection();
  }, []);

  const handleConnectWallet = () => {
    initializeWeb3();
  };

  const initializeWeb3 = async () => {
    if (window.ethereum) {
      const newWeb3 = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        const newAccounts = await newWeb3.eth.getAccounts();
        setWeb3(newWeb3);
        setAccount(newAccounts[0]);
        setIsConnected(true);
        getTokenBalance(newAccounts[0]);
      } catch (error) {
        console.error(error);
      }
    } else if (window.web3) {
      const newWeb3 = new Web3(window.web3.currentProvider);
      try {
        const newAccounts = await newWeb3.eth.getAccounts();
        setWeb3(newWeb3);
        setAccount(newAccounts[0]);
        setIsConnected(true);
        getTokenBalance(newAccounts[0]);
      } catch (error) {
        console.error(error);
      }
    } else {
     
    }
  };

  const checkWalletConnection = async () => {
    if (window.ethereum) {
      const newWeb3 = new Web3(window.ethereum);
      try {
        const accounts = await newWeb3.eth.getAccounts();
        if (accounts.length > 0) {
          setWeb3(newWeb3);
          setAccount(accounts[0]);
          setIsConnected(true);
          getTokenBalance(accounts[0]);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (window.web3) {
      const newWeb3 = new Web3(window.web3.currentProvider);
      try {
        const accounts = await newWeb3.eth.getAccounts();
        if (accounts.length > 0) {
          setWeb3(newWeb3);
          setAccount(accounts[0]);
          setIsConnected(true);
          getTokenBalance(accounts[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getTokenBalance = async (walletAddress) => {
    const walletstr = walletAddress.toString();
    const url = `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=0x6a63e299699D65A635c0606BEc60eBA4067C0E7c&address=${walletstr}&tag=latest&apikey=W9Z53R9N1YI94ZE8PVB39S6CSHA9ZK8WJC`;
    
    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "1") {
        const tokenBalance = data.result;
        const BalanceFloatBig = parseFloat(tokenBalance);
        const BalanceFloat = BalanceFloatBig / Math.pow(10, 9);
        setTokenBalance(BalanceFloat);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  return (
    <div>
      <ul className="navbar">
        <li className="logo"><img src={logo} alt="Logo" /></li>
        <li><a href="#" onClick={() => handlePageChange('home')} className={currentPage === 'home' ? 'active' : ''}>Home</a></li>
        <li className="dropdown">
          <a href="#" className="dropbtn">Tools</a>
          <div className="dropdown-content">
            <a href="#" onClick={() => handlePageChange('Categori1')} className={currentPage === 'Categori1' ? 'active' : ''}>News</a>
            <a href="#" onClick={() => handlePageChange('Categori2')} className={currentPage === 'Categori2' ? 'active' : ''}>Alpha Tools</a>
            <a href="#" onClick={() => handlePageChange('Categori3')} className={currentPage === 'Categori3' ? 'active' : ''}>Callers</a>
          </div>
        </li>
        <li><a href="#" onClick={() => handleConnectWallet()}>{isConnected ? ' Connected'  : 'Connect Wallet'}</a></li>
      </ul>
      <div>
        {currentPage === 'home' && <Home handlePageChange={handlePageChange} />}
        {currentPage === 'Categori1' && <Categori1 handlePageChange={handlePageChange} tokenBalance={tokenBalance} />}
        {currentPage === 'Categori2' && <Categori2 handlePageChange={handlePageChange} tokenBalance={tokenBalance} />}
        {currentPage === 'Categori3' && <Categori3 handlePageChange={handlePageChange} tokenBalance={tokenBalance} />}
        {currentPage === 'tool1' && <Tool1Component />}
        {currentPage === 'tool2' && <Tool2 />}
        {currentPage === 'tool3' && <Tool3 />}
        {currentPage === 'tool4' && <Tool4 />}
        {currentPage === 'tool5' && <Tool5 />}
        {currentPage === 'tool6' && <Tool6 />}
        {currentPage === 'tool7' && <Tool7 />}
        {currentPage === 'tool8' && <Tool8 />}
        {currentPage === 'tool9' && <Tool9 />}
        {currentPage === 'tool10' && <Tool10 />}
        {currentPage === 'tool11' && <Tool11 />}
        {currentPage === 'tool12' && <Tool12 />}
        {currentPage === 'tool13' && <Tool13 />}
        {currentPage === 'redirect' && <RedirectPage/>}
      </div>
    </div>
  );
}

export default App;
