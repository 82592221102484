import React, { useEffect } from 'react';

const TradingViewTickerTape = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        {
          proName: "BITSTAMP:BTCUSD",
          title: "Bitcoin"
        },
        {
          proName: "BINANCE:ETHUSDT",
          title: "Ethereum"
        },
        {
          proName: "BINANCE:XRPUSDT",
          title: "Xrp"
        },
        {
          proName: "BINANCE:MATICUSDT",
          title: "Matic"
        },
        {
          proName: "BINANCE:SOLUSDT",
          title: "Solana"
        },
        {
          proName: "BINANCE:ADAUSDT",
          title: "Cardano"
        },
        {
          proName: "BINANCE:BNBUSDT",
          title: "Bnb"
        }
      ],
      showSymbolLogo: true,
      colorTheme: "light",
      isTransparent: false,
      displayMode: "adaptive",
      locale: "en"
    });
    document.getElementById('tradingview-ticker-tape').appendChild(script);
  }, []);
 
  return (
    <div id="tradingview-ticker-tape" className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
      </div>
    </div>
  );
}

export default TradingViewTickerTape;
