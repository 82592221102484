import React from 'react';
import './Tools.css';
const Categori3 = ({ handlePageChange , tokenBalance }) => {
  const handleToolClick = (toolName) => {
    if (tokenBalance >= 250000) {
      handlePageChange(toolName);
    }else{
      handlePageChange('redirect');
    }
  };

  return (

    <div className="tools-grid">
      <div className="tool-box" onClick={() => handleToolClick('tool6')}>
        <h2>Eric Cryptomans Journals</h2>
        <p>Eric Cryptoman Signals</p>
      </div>
      <div className="tool-box" onClick={() => handleToolClick('tool7')}>
        <h2>Maythous Calls</h2>
        <p>Maythous Calls and Updates</p>
      </div>
      <div className="tool-box">
        <h2>Coming soon</h2>
        <p>Coming soon</p>
      </div>
    </div>
  );
};

export default Categori3;
