import React, { useState, useEffect } from 'react';
import './Tool1.css';
function Tool3() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const collectionName = 'chat_1001664091682'; 

    fetch(`https://45.14.135.47:3001/api/messages/chat_1001664091682`)
      .then((response) => response.json())
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
      });
  }, []);

  return (
    <div className='mess-box'>
      <div className="message-list">
        {messages.map((message, index) => (
          <div key={index} className="message-box">
            <div className="message-arrow-left"></div>
            <div className="message-text">{message.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tool3;

