import React from 'react';
import "./Redirect.css";
function RedirectPage() {
  const redirectToAnotherPage = () => {
    window.location.href = 'https://app.uniswap.org/swap?exactField=input&exactAmount=0&outputCurrency=0x6a63e299699d65a635c0606bec60eba4067c0e7c'; 
  };

  return (
    <div className="box">
      <p className="info-not">You don't have enought balance to use this tool. You can buy $ALEPH by clicking on the below button.</p>
      <button className="redirect-button" onClick={redirectToAnotherPage}>
          Purchase now
      </button>
    </div>
  );
}

export default RedirectPage;
