import React, { useState, useEffect } from 'react';
import './Home.css';
import logo from './alephFavicon.ico';
import Trading from './TicketTape.js';
import Alogo from './imageedit_4_8152950478.png';
import Aimage from './AlephStructure.png';
function Home({ handlePageChange}) {
  const handleToolClick = (category) => {
    handlePageChange(category);
  };
  
  const handleWhiteButton = () => {
    window.location.href = 'https://app.uniswap.org/swap?exactField=input&exactAmount=0&outputCurrency=0x6a63e299699d65a635c0606bec60eba4067c0e7c';
  };

  const handleBlackButton = () => {
    window.location.href = 'https://aleph-whitepaper.gitbook.io/aleph-whitepaper/';
  };

   const handleLocked = () => {
    window.location.href = 'https://app.uncx.network/amm/uni-v2/pair/0x912515e5f2a93a66124acd9f496506c81d4ed96a';
  }; 

  const handleRenounced = () => {
    window.location.href = '';
  };
  
  const handleTelegram = () => {
    window.location.href = 'https://t.me/alephportal';
  };
  
  const handleTwitter = () => {
    window.location.href = 'https://twitter.com/AlephTerminal';
  };

  const handleMedium = () => {
    window.location.href = 'https://www.dextools.io/app/en/ether/pair-explorer/0x912515e5f2a93a66124acd9f496506c81d4ed96a';
  };
  
  const downloadPDF = () => {
    // URL del file PDF sul server remoto
    const pdfURL = 'https://45.14.135.47:3001/api/download-pdf';

    // Crea un link temporaneo
    const link = document.createElement('a');
    link.href = pdfURL;

    // Imposta l'attributo "download" per indicare al browser di scaricare il file invece di aprirlo
    link.download = 'whitepaper.pdf';

    // Simula un clic sul link per avviare il download
    link.click();
  };
  
  return (
    <div className="App">
      <div className="home-container">
        <div className="home-content">
          <h1 className="home-title">
            <span className="logo-letter">
              <img src={Alogo} alt="A" />
            </span>
          </h1>
          <h2 className="subtitle-ca">0x6a63e299699D65A635c0606BEc60eBA4067C0E7c</h2>
        </div>
        <div className="button-container">
          <button className="white-button" onClick={handleWhiteButton}>Purchase Now</button>
          <button className="black-button" onClick={handleBlackButton}>Whitepaper</button>
        </div>
      </div>
      <Trading/>
      <div className="blue-box">
        <div className="white-boxes">
           <div className="single-transparent-box">
              <p className="title-blue"> ALPHA INSIGHTS </p>
              <p className="subtitle-blue">At the Heart of Our Mission: Empowering Crypto Enthusiasts with Unparalleled Alpha Insights and a Wealth of Comprehensive Knowledge to Help Navigate the Evolving Cryptocurrency Landscape. </p>
           </div>
           <div className="single-transparent-box">
              <p className="title-blue"> TRADING SIGNALS </p>
              <p className="subtitle-blue">Join Us in an Expedition to Explore a Vast Wealth of Alpha Tokens, and Immerse Yourself in a Multifaceted World of Trading Signals to Enhance Your Financial Journey. </p>
           </div>
           <div className="single-transparent-box">
              <p className="title-blue"> CRYPTO CALLS </p>
              <p className="subtitle-blue">Introducing a Revolutionary Concept: Crypto Callers and Crypto Calls Converge in a Singular, All-in-One Project, Fostering a Seamless and Inclusive Ecosystem for Digital Assets Enthusiasts to Thrive and Grow.</p>
           </div>
        </div>
      </div>
      <img className="scaling" src={Aimage} alt="Logo" />
      <div className="blue-box how-it-works">
        <p className="disclaimer-blue">HOW IT WORKS</p>
        <p className="subtitle-blue"> Aleph Aggregator grants access to its comprehensive suite of crypto tools and resources. ALEPH tokens serve as your key to enter our dApp and gain unrestricted access to a wealth of essential tools.
        You can explore as array of aleph tokens, trading signals, crypto callers and crypto calls. Our platform is designed to empower crypto enthusiasts of all levels, from beginners to seasoned traders, providing a one-step solution for your crypto needs </p>
      </div>
      <div className="white-boxes tokenomics">
        <div className="single-box">
          <p className="right-align-logo" ><img src={logo} alt="Logo" /></p>
          <p className="title-blue tokenomics"> TOKENOMICS </p>
          <p className="info-not">Supply: 100M</p>
          <p className="info-not">Slippage: 5%</p>
          <p className="info" onClick={handleLocked}>Locked LP </p>
          <p className="info" onClick={handleRenounced}>Renounced </p>
        </div>
        <div className="single-box">
          <p className="right-align-logo" ><img src={logo} alt="Logo" /></p>
          <p className="title-blue tokenomics"> COMMUNITY </p>
          <p className="info" onClick={handleTelegram}>Telegram</p>
          <p className="info" onClick={handleTwitter}>Twitter</p>
          <p className="info" onClick={handleMedium}>Dextools</p>
        </div>
        <div className="single-box">
          <p className="right-align-logo" ><img src={logo} alt="Logo" /></p>
          <p className="title-blue tokenomics"> QUICK LINKS </p>
          <p className="info" onClick={() => handleToolClick('Categori1')} >News</p>
          <p className="info" onClick={() => handleToolClick('Categori2')} >Alpha Tools</p>
          <p className="info" onClick={() => handleToolClick('Categori3')} >Callers</p>
        </div>
      </div>
      <div className="blue-box-footer">
        <div className="subtitle-blue">
          2023 ALEPH all rights reserved
        </div>
      </div>
    </div>
  );
}

export default Home;

